import { Dispatch, SetStateAction, useContext } from "react";
import TransitionWorkflowPageSideBar from "./transitions/TransitionWorkflowPageSideBar";
import WorkflowNoSource from "./WorkflowNoSource";
import { useModal, WorkflowContext } from "../contexts";
import WorkflowSourceFieldTable from "./WorkflowSourceFieldTable";
import ToggleButton from "./ToggleButton";
import ProLabel from "./ProLabel";
import useUser from "../hooks/useUser";
import WorkflowPaymentConfig from "./WorkflowPaymentConfig";
import { DocumentTextIcon, PaperClipIcon } from "@heroicons/react/outline";
import WorkflowSelectModal from "./WorkflowSelectModal";
import { Email } from "../interfaces";
import PayWallModalPro from "./PayWallModalPro";
import { putData } from "../backend";

type Props = {
  email: Email;
  setEmail: Dispatch<SetStateAction<Email>>;
  toggleAttachmentMenu: () => void;
}

function WorkflowEmailSidebar(props: Props) {
  const { email, setEmail, toggleAttachmentMenu } = props;
  const workflowContext = useContext(WorkflowContext)
  const { workflow, mutateWorkflow } = workflowContext;
  const { user } = useUser();
  const { openModal } = useModal();

  const isWorkflowOwner = (workflow.owner?.id === user?.id);

  async function updateEnableTracking(enableTracking: boolean) {
    if (!user?.subscription?.featureSignatures) // TODO: Change this to its own feature flag
      return openModal(<PayWallModalPro />);

    let updatedEmail = { ...email!, enableTracking };
    setEmail(updatedEmail);

    updatedEmail = await putData(`/workflows/emails/${email.id}/`, updatedEmail);
    setEmail(updatedEmail);
  }

  async function updateSendFromOwner(sendFromOwner: boolean) {
    let updatedEmail = { ...email!, sendFromOwner };
    setEmail(updatedEmail);

    updatedEmail = await putData(`/workflows/emails/${email.id}/owner-update/`, updatedEmail);
    setEmail(updatedEmail);
  }

  function handleIncludeBrandingToggle(includeBranding: boolean) {
    if (!user?.subscription?.featureRemoveBranding)
      openModal(<PayWallModalPro reason="BRANDING" />);
  }



  return (

    <TransitionWorkflowPageSideBar>

      {!workflow.source && <WorkflowNoSource />}

      {workflow.source && <>
        <WorkflowSourceFieldTable workflow={workflow} mutateWorkflow={mutateWorkflow} />

        <div className='flex flex-col gap-1'>
          <div className='text-sm font-semibold font-gilroy text-gray-600'>
            Email Tracking
          </div>
          <div className='flex items-center gap-2'>
            <ToggleButton value={email.enableTracking} onChange={updateEnableTracking} />
            <div className='text-xs font-semibold text-gray-400 flex-shrink'>
              View when each email is opened
            </div>
            <ProLabel />
          </div>
        </div>

        <div className='flex flex-col gap-1'>
          <div className='text-sm font-semibold font-gilroy text-gray-600'>
            Portant branding
          </div>
          <div className='flex items-center gap-2'>
            <ToggleButton value={!!user?.subscription?.featureRemoveBranding} onChange={handleIncludeBrandingToggle} disabled={!user?.subscription?.featureRemoveBranding} />
            <div className='text-xs font-semibold text-gray-400 flex-shrink'>
              Remove "Sent via Portant" in email
            </div>
            <ProLabel />
          </div>
        </div>

        {(workflow.team && false) && // Currently disabled
          <div className='flex flex-col gap-1'>
            <div className='text-sm font-semibold font-gilroy text-gray-600'>
              Send from Workflow Owner
            </div>

            <div className='flex flex-col'>
              <div className='flex items-center gap-2'>
                <ToggleButton value={email!.sendFromOwner} onChange={updateSendFromOwner} disabled={!isWorkflowOwner} />
                <div className='text-xs font-semibold text-gray-400 flex-shrink'>
                  Always send emails from the workflow owner
                </div>
              </div>
              {!isWorkflowOwner &&
                <div className='text-xs font-semibold text-gray-600 w-full text-center mt-1'>
                  This settings can only be configured by the workflow&nbsp;owner: <b>{workflow.owner?.fullName}</b>
                </div>
              }
            </div>
          </div>
        }

        {workflow.documents.length > 0 &&
          <button className='text-sm btn btn-blue' onClick={toggleAttachmentMenu}>
            Add Attachments <PaperClipIcon className='w-4 h-4 ml-2 text-white' />
          </button>

        }

        {workflow.documents.length === 0 && <>
          <hr />
          <div className="flex flex-col gap-2">
            <div className="flex flex-col gap-1">
              <div className="flex items-center font-gilroy font-semibold text-gray-600">
                <DocumentTextIcon className="w-6 h-6 mr-1" /> Add document
              </div>
              <div className="font-semibold text-xs text-gray-400">
                Add a templated document to be included with this email
              </div>
            </div>
            <button className="btn btn-white w-40 text-xs" onClick={() => openModal(<WorkflowSelectModal title="Add document" subtitle="Add a templated document to be included with this email" showDocuments {...workflowContext} />)}>
              Add
            </button>
          </div>
        </>}

        <hr />
        <WorkflowPaymentConfig />

      </>}
    </TransitionWorkflowPageSideBar>
  );
}

export default WorkflowEmailSidebar;

import { Transition } from '@headlessui/react'
import { XIcon } from "@heroicons/react/outline";
import { useContext, useEffect, useRef, useState } from "react";
import { deleteData, OAuthError, patchData, postData, putData } from "../backend";
import { WorkflowContext, useModal } from "../contexts";
import { BLOCK_ICON_MAP } from "../utils";
import useUser from "../hooks/useUser";
import { Email, AttachmentType } from "../interfaces";
import WorkflowBlockModalSave from './WorkflowModalSave';
import WorkflowSelectModal from './WorkflowSelectModal';
import OAuthModal from './OAuthModal';
import OAuthModalMicrosoft from './OAuthModalMicrosoft';
import BetaLabel from './BetaLabel';
import EmailModalAttachment from './EmailModalAttachment';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import TransitionWorkflowPage from './transitions/TransitionWorkflowPage';
import WorkflowEmailFrom from './WorkflowEmailFrom';
import WorkflowEmailTo from './WorkflowEmailTo';
import WorkflowEmailSubject from './WorkflowEmailSubject';
import WorkflowEmailSidebar from './WorkflowEmailSidebar';
import WorkflowEmailSave from './WorkflowEmailSave';
import WorkflowEmailAttachments from './WorkflowEmailAttachments';
import useRichTextfield from '../hooks/useRichTextfield';
import WorkflowEmailToolbar from './WorkflowEmailToolbar';

type SaveState = "INITIAL" | "HAS_CHANGES" | "SAVING" | "SAVED";

function WorkflowEmail() {
  const { user, hasPermission } = useUser();
  const location = useLocation();
  const history = useHistory();
  const { url } = useRouteMatch();

  const workflowContext = useContext(WorkflowContext)
  const { workflow, mutateWorkflow } = workflowContext;

  const allowEditing = hasPermission(workflow.team, "workflows.edit");

  const index = (() => {
    const hash = location.hash.split("#")[1];
    return (parseInt(hash) || 1) - 1;
  })();

  const initialEmail = workflow.emails[index] ?? workflow.emails[0];
  const attachments = (initialEmail ?? []).attachments;
  const richEditorRef = useRef<HTMLDivElement | null>(null);

  // TODO: Eventually replace every field in "email" for this new variable
  // The issue with signatureConfig is that it updates the back instantaneously (with a post) without waiting for the user to *save* the changes
  // The current fields are a bit complex so I'd rather replace them bit by bit to prevent breaking everything
  const [emailConfig, setEmailConfig] = useState<Email>({
    id: initialEmail?.id ?? 0,
    updatedAt: initialEmail?.updatedAt ?? '',
    createdAt: initialEmail?.createdAt ?? '',
    enableTracking: initialEmail?.enableTracking ?? '',
    attachments: initialEmail?.attachments ?? [],
    status: initialEmail?.status ?? '',
    emailContent: initialEmail?.emailContent ?? '',
    emailFromAlias: initialEmail?.emailFromAlias,
    emailTo: initialEmail?.emailTo ?? '',
    emailCc: initialEmail?.emailCc ?? "",
    emailBcc: initialEmail?.emailBcc ?? "",
    isHtmlMode: initialEmail?.isHtmlMode ?? false,
    emailSubject: initialEmail?.emailSubject ?? '',
    emailType: workflow.signatureConfig?.emailType ?? 'GMAIL',
    sendFromOwner: !!workflow.signatureConfig?.sendFromOwner,
    aliasDisplay: workflow.signatureConfig?.aliasDisplay ?? '',
  })

  const [addingAttachment, setAddingAttachment] = useState<boolean>(false);
  const [removingAttachmentId, setRemovingAttachmentId] = useState<string>();
  const [updatingAttachmentId, setUpdatingAttachmentId] = useState<string>();
  const [saveState, setSaveState] = useState<SaveState>("INITIAL");

  const [hasCheckedScopes, setHasCheckedScopes] = useState(false);

  const { openModal, closeModal } = useModal();

  const {
    RichTextfield,
    addImage,
    addLink,
    applyFormat,
    restoreLastSelection,
    insertCustom
  } = useRichTextfield({
    content: emailConfig?.emailContent ?? '',
    setContent: (value: string) => updateEmailConfig('emailContent', value),
    editorRef: richEditorRef,
    HTMLMode: emailConfig?.isHtmlMode,
    switchMode: (value: boolean) => updateEmailConfig('isHtmlMode', value),
    allowHTMLMode: true,
  });

  useEffect(() => {
    if (!initialEmail)
      return;

    setEmailConfig(initialEmail);
    // Set email with defaults from existing values or user's defaults
    setEmailConfig({ ...initialEmail });
  }, [user, workflow, initialEmail]);

  useEffect(() => {
    if (hasCheckedScopes || !initialEmail || !location.pathname.endsWith("/email"))
      return;

    if (initialEmail.emailType === "GMAIL")
      postData("/auth/scopes/", { scopes: ["https://www.googleapis.com/auth/gmail.send"] })
        .catch(error => {
          if (error instanceof OAuthError)
            openModal(<OAuthModal title='Portant requires your permission to send emails' oauthUrl={error.oauthUrl} />);
        })
        .finally(() => setHasCheckedScopes(true));

    else if (initialEmail.emailType === "OUTLOOK")
      postData("/microsoft/access-token/", {})
        .catch(error => {
          if (error instanceof OAuthError)
            openModal(<OAuthModalMicrosoft oauthUrl={error.oauthUrl} />);
        })
        .finally(() => setHasCheckedScopes(true));

  }, [hasCheckedScopes, openModal, initialEmail, location]);

  function updateEmailConfig(field: string, value: any) {
    setSaveState("HAS_CHANGES");
    setEmailConfig(prev => ({ ...prev, [field]: value }));
  }

  async function handleSave() {
    let updatedEmail = {
      ...emailConfig!,
      status: emailConfig.status ?? '',
      emailType: emailConfig.emailType ?? 'GMAIL',
    }

    setSaveState("SAVING");


    updatedEmail = await putData(`/workflows/emails/${initialEmail.id}/`, updatedEmail);
    setSaveState("SAVED");

    await mutateWorkflow({ ...workflow, emails: workflow.emails.map(e => e.id === initialEmail.id ? updatedEmail : e) });
  }

  function addAttachment(attachmentType: AttachmentType, payload: any) {
    closeModal();
    setAddingAttachment(true);

    let options: Record<string, string> = {};
    if (attachmentType === "INSERT_OUTPUT_AS_LINK")
      options["sharingMode"] = "VIEWER";

    postData(`/workflows/emails/${initialEmail.id}/attachments/`, { attachmentType, ...payload })
      .then(() => mutateWorkflow())
      .catch(console.error)
      .finally(() => setAddingAttachment(false));
  }

  function removeAttachment(attachmentId: string) {
    closeModal();
    setRemovingAttachmentId(attachmentId);

    deleteData(`/workflows/attachments/${attachmentId}/`)
      .then(() => mutateWorkflow())
      .catch(console.error)
      .finally(() => setRemovingAttachmentId(undefined));
  }

  function updateAttachment(attachmentId: string, sharingMode: string) {
    setUpdatingAttachmentId(attachmentId);

    patchData(`/workflows/attachments/${attachmentId}/`, { sharingMode })
      .then(() => mutateWorkflow())
      .catch(console.error)
      .finally(() => setUpdatingAttachmentId(undefined));
  }

  function toggleAttachmentMenu() {
    openModal(<EmailModalAttachment workflow={workflow} attachments={attachments!} addAttachment={addAttachment} removeAttachment={removeAttachment} showMicrosoft postSigningStage />)
  }


  function handleAttemptClose() {
    if (allowEditing && saveState === "HAS_CHANGES")
      return openModal(<WorkflowBlockModalSave onSave={() => handleSave().then(() => history.push(url))} onDontSave={() => history.push(url)} />);

    history.push(url);
  }


  if (!initialEmail || !emailConfig) {
    if (!location.pathname.endsWith("/email"))
      return null;

    return (
      <div className="flex flex-col items-center justify-center gap-4 h-full w-full">
        <div className="font-semibold font-gilroy text-gray-600 text-center w-96">
          This workflow contains no email templates, would you like to add some?
        </div>
        <button className="btn btn-blue" onClick={() => openModal(<WorkflowSelectModal title="Add Workflow Template" subtitle="Add a template to your workflow" showEmails workflowContext={workflowContext} />)}>
          Add Email Template
        </button>
      </div>
    );
  }

  return (
    <TransitionWorkflowPage show={location.pathname.endsWith("/email")}>

      {allowEditing && <WorkflowEmailSidebar email={emailConfig} setEmail={setEmailConfig} toggleAttachmentMenu={toggleAttachmentMenu} />}

      <Transition.Child className="flex flex-col bg-white w-[800px] m-auto border border-gray-200 shadow rounded overflow-hidden"
        enter="transition-opacity duration-150"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className='bg-gray-100 flex items-center gap-2 px-2 py-1 border-b border-gray-200'>
          <img className="h-4 w-4 rounded-md" src={BLOCK_ICON_MAP[emailConfig.emailType]} alt="Gmail Icon" />
          <div className='font-gilroy font-semibold text-gray-600 text-sm'>
            {emailConfig.emailType === "GMAIL" ? <>Share via Gmail</> : <span className='flex gap-3'>Share via Outlook <BetaLabel /></span>}
          </div>
          <XIcon className="w-5 h-5 ml-auto text-gray-600 hover:text-red cursor-pointer" onClick={handleAttemptClose} />
        </div>
        <div className="flex flex-col h-full p-2">
          <WorkflowEmailFrom emailConfig={emailConfig} onChange={updateEmailConfig} workflow={workflow} />
          <WorkflowEmailTo email={emailConfig} onChange={updateEmailConfig} allowEditing={allowEditing} />
          <hr className="border-gray-400" />
          <WorkflowEmailSubject email={emailConfig} onChange={updateEmailConfig} />
          <hr className="border-gray-400 pb-2" />
          <div className="flex flex-col h-[400px]">
            {RichTextfield}
            <WorkflowEmailAttachments
              addingAttachment={addingAttachment}
              attachments={attachments}
              removeAttachment={removeAttachment}
              updateAttachment={updateAttachment}
              removingAttachmentId={removingAttachmentId}
              updatingAttachmentId={updatingAttachmentId}
            />

            {allowEditing &&
              <div className="flex flex-col flex-shrink-0">
                <div className="my-1 flex items-center z-10 flex-shrink-0 w-full">
                  <WorkflowEmailToolbar
                    allowModeSwitch
                    htmlMode={emailConfig.isHtmlMode}
                    applyFormat={applyFormat}
                    addLink={addLink}
                    addImage={addImage}
                    toggleAttachmentMenu={toggleAttachmentMenu}
                    onContentModeSwitch={() => updateEmailConfig("isHtmlMode", !emailConfig.isHtmlMode)}
                    restoreLastSelection={restoreLastSelection}
                    insertCustom={insertCustom}
                  />
                  <WorkflowEmailSave allowEditing={allowEditing} handleSave={handleSave} saveState={saveState} />
                </div>
              </div>
            }
          </div>
        </div>
      </Transition.Child>
    </TransitionWorkflowPage>
  );

}

export default WorkflowEmail;

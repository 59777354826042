import { ChevronDownIcon } from '@heroicons/react/outline';
import { useId } from 'react';

interface Props {
  label?: string;
  className?: string;
  children: JSX.Element | JSX.Element[] | React.ReactNode;
  value: string;
  onChange: (value: string) => void;
  onClick?: () => void;
  required?: boolean
  dsiabled?: boolean
}

function SelectField(props: Props) {

  const id = useId();

  return (
    <div className={"relative flex flex-col " + props.className}>
      <div className="relative w-full">
        <select
          id={id}
          className={`border-2 rounded-md h-10 pl-2 w-full disabled:bg-gray-100 ${props.required && !props.value ? "border-yellow" : "border-gray-400"} ${props.label ? 'mt-2' : ''}`}
          value={props.value}
          onChange={(e) => props.onChange && props.onChange(e.target.value)}
          onClick={props.onClick}
          disabled={props.dsiabled}
          style={{
            // Remove default arrow for select field
            MozAppearance: 'none',
            WebkitAppearance: 'none',
          }}>
          {props.children}
        </select>
        {props.label && <label htmlFor={id} className="text-xs font-gilroy font-semibold text-gray-600 bg-white absolute left-2 top-0 px-1 w-auto">
          {props.label}
        </label>}
        <ChevronDownIcon className={`w-6 h-6 absolute right-2 pointer-events-none text-gray-400 ${props.label ? 'top-4' : 'top-2'}`} />
      </div>
    </div>
  );
};

export default SelectField;

import { ArrowRightIcon, BadgeCheckIcon, BanIcon, CheckCircleIcon, ChevronDownIcon, ClipboardCopyIcon, ClockIcon, DocumentTextIcon, DotsCircleHorizontalIcon, DotsHorizontalIcon, DotsVerticalIcon, ExclamationCircleIcon, ExclamationIcon, ExternalLinkIcon, EyeIcon, MailIcon, MailOpenIcon, RefreshIcon, TrashIcon } from "@heroicons/react/outline";
import { TrackingPixel, TrackingPixelEvent, WorkflowAutomationItem } from "../interfaces";
import { BLOCK_ICON_MAP, MIMETYPE_ICON_MAP } from "../utils";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { WorkflowContext, useModal } from "../contexts";
import { OAuthError, deleteData, postData } from "../backend";
import { Popover, Transition } from "@headlessui/react";
import OAuthModal from "./OAuthModal";
import CopyToClipboard from "react-copy-to-clipboard";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import useUser from "../hooks/useUser";
import ErrorModal from "./ErrorModal";

interface Props {
  item: WorkflowAutomationItem;
  lastViewedTime: number;
  hidePreview?: boolean;
}

function WorkflowOutputsItem(props: Props) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [resendStatus, setResendStatus] = useState<"INITIAL" | "SENDING" | "RESENT" | "ERROR">("INITIAL");

  const { hasPermission } = useUser();

  const history = useHistory();
  const { url } = useRouteMatch();

  const [actionStatus, setActionStatus] = useState<"INITIAL" | "DELETED" | "RERUNNING">("INITIAL");

  const [showCopiedMessage, setShowCopiedMessage] = useState(false);
  const [copiedSignatureRequest, setCopiedSignatureRequest] = useState<number>();

  const [item, setItem] = useState(props.item)
  const { outputs, outputEmails, signatureRequests, } = item;

  const { openModal } = useModal();

  const documentOutputs = outputs.filter(output => !!output.file);
  const pdfOuputs = outputs.filter(output => (!!output.filePdf));
  const microsoftOuputs = outputs.filter(output => (!!output.fileMicrosoft));

  const { workflow } = useContext(WorkflowContext);

  const allTrackingPixels: Array<TrackingPixel> = [...item.signatureRequests.map(s => s.trackingPixel), ...item.outputEmails.map(e => e.trackingPixel)].filter(Boolean);
  const allTrackingPixelEvents: Array<TrackingPixelEvent> = allTrackingPixels.map(p => p.events).flat();

  useEffect(() => setItem(props.item), [props.item]);

  function handleResendSignatureRequest(id: string) {
    setResendStatus("SENDING");

    postData(`/signatures/resend/${id}/`)
      .then(() => setResendStatus("RESENT"))
      .catch(() => setResendStatus("ERROR"))
  }

  function handleDeleteItem() {
    setActionStatus("DELETED");
    deleteData(`/workflows/automation-items/${item.id}/`)
      .catch(() => setActionStatus("INITIAL"));
  }

  function handleRerunItem() {
    setActionStatus("RERUNNING");
    postData(`/workflows/automation-items/${item.id}/rerun/`)
      .catch(error => {
        setActionStatus("INITIAL");
        if (error instanceof OAuthError)
          openModal(<OAuthModal oauthUrl={error.oauthUrl} />);
        else
          openModal(<ErrorModal details={error} />);
      });
  }

  function handleRescindItem() {
    setActionStatus("RERUNNING");

    postData(`/workflows/automation-items/${item.id}/rescind/`)
      .then(setItem)
      .catch(console.error)
      .finally(() => setActionStatus("INITIAL"));
  }

  function handleOnCopySupport() {
    setShowCopiedMessage(true);
    setTimeout(() => setShowCopiedMessage(false), 2000);
  }

  function handleOnCopiedSignatureRequest(recipient: number) {
    setCopiedSignatureRequest(recipient);
    setTimeout(() => setCopiedSignatureRequest(undefined), 2000);
  }

  const itemOutputs: Array<{ name?: string, icon: string } | undefined> = [
    ...outputs.map(output => ({ name: output.file?.name, icon: MIMETYPE_ICON_MAP[output.file?.mimeType ?? "application/vnd.google-apps.document"] })),
    ...outputs.map(output => ({ name: output.filePdf?.name, icon: MIMETYPE_ICON_MAP["application/pdf"] })),
    ...outputs.map(output => ({ name: output.fileMicrosoft?.name, icon: MIMETYPE_ICON_MAP[output.fileMicrosoft?.mimeType ?? "application/vnd.google-apps.file"] })),
    ...outputEmails.map(email => ({ name: email.emailSubject, icon: BLOCK_ICON_MAP[email.emailType] }))
  ].filter(item => !!item.name);

  const [primaryOutput, ...remainingOutputs] = itemOutputs;

  return (
    <>
      <tr className={`text-sm font-semibold font-gilroy text-gray-600 cursor-pointer ${!isExpanded && "border-b border-gray-200"}`} onClick={(e) => { setIsExpanded(f => !f); e.stopPropagation(); }}>
        {/* Expansion indicator */}
        <td className="h-14 w-6 pl-4">
          <button onClick={(e) => { setIsExpanded(f => !f); e.stopPropagation(); }}>
            <ChevronDownIcon className={`w-6 h-6 text-gray-400 cursor-pointer transform transition-all ${isExpanded ? "rotate-0" : "-rotate-90"}`} />
          </button>
        </td>

        {/* Source item number */}
        <td className="h-14 text-center w-20">
          {props.item.sourceItemNumber || "-"}
        </td>

        {/* Filename */}
        <td className="h-14 mr-auto">
          <div className="relative flex gap-2 items-center group">
            {props.lastViewedTime < new Date(item.updatedAt).getTime() &&
              <div className="absolute bg-green border-white drop-shadow-sm w-2 h-2 -left-4 rounded-full" />
            }

            {(!primaryOutput && item.status === 'PENDING') &&
              <div className="text-gray-500 font-semibold">
                In progress...
              </div>
            }

            {(!primaryOutput && item.status !== 'PENDING') &&
              <div className="text-yellow font-semibold">
                No outputs or emails were created for this automation item
              </div>
            }

            {primaryOutput &&
              <>
                <img src={primaryOutput.icon} className="w-4 h-4" alt="Output Icon" />
                <div className={`max-w-[400px] truncate ${actionStatus !== "INITIAL" ? "text-gray-400 line-through" : "text-gray-600"}`}>
                  {primaryOutput.name}
                </div>
              </>
            }

            {remainingOutputs.length > 0 &&
              <div className="ml-1 text-gray-400">+</div>
            }

            {remainingOutputs.map((output, index) => <img key={index} src={output?.icon} className="w-4 h-4" alt="Output Icon" />)}

            {(!props.hidePreview && actionStatus === "INITIAL" && primaryOutput && !primaryOutput.icon?.endsWith("pdf-icon.svg")) &&
              <Link to={url + "/outputs?preview=" + props.item.id} className="opacity-0 group-hover:opacity-100 text-gray-400 hover:text-blue font-semibold font-gilroy text-sm transition-opacity ml-4">
                Preview
              </Link>
            }
          </div>
        </td>

        {/* Tracking */}
        <td className="h-14 w-20">
          {(allTrackingPixels.length > 0 && allTrackingPixelEvents.length === 0) &&
            <div className="relative group cursor-default">
              <EyeIcon className="w-6 h-6 text-gray-400 mx-auto" />
              <div className="hidden group-hover:block absolute bg-white shadow-lg text-center w-72 -right-32 top-6 z-10 p-2 rounded border border-gray-200">
                No views yet
              </div>
            </div>
          }

          {(allTrackingPixels.length > 0 && allTrackingPixelEvents.length > 0) &&
            <div className="relative group cursor-default">
              <EyeIcon className="w-6 h-6 text-blue mx-auto" />
              <div className="hidden group-hover:block absolute bg-white shadow-lg text-center w-72 -right-32 top-6 z-10 p-2 rounded border border-gray-200">
                <span className="font-semibold">{allTrackingPixelEvents.length}</span> view{allTrackingPixelEvents.length >= 2 ? "s" : ""}, last viewed {moment(allTrackingPixelEvents[0].createdAt).fromNow()}
              </div>
            </div>
          }
        </td>

        {/* Automation User */}
        <td className="h-14 w-52">
          {!!workflow.team && item.automationUser &&
            <p className="font-gilroy font-semibold text-sm text-gray-400 w-52 truncate m-auto">
              Ran by&nbsp;<span className="text-gray-600">{item.automationUser.fullName}</span>
            </p>
          }
        </td>

        {/* Status */}
        <td className="text-center w-44 h-14">
          {actionStatus === "INITIAL" &&
            <>
              {item.status === "PENDING" && <DotsCircleHorizontalIcon className="w-6 h-6 text-blue mx-auto" />}
              {item.status === "COMPLETED" && <CheckCircleIcon className="w-6 h-6 text-green mx-auto" />}
              {item.status === "COMPLETED_PARTIALLY" &&
                <>
                  {item.stage === "REVIEW_PENDING"
                    ? <button className="btn btn-blue text-xs py-1 mx-auto" onClick={(e) => { e.stopPropagation(); history.push(url + "/review?" + new URLSearchParams({ item: item.id }).toString()); }}>Review</button>
                    : <DotsCircleHorizontalIcon className="w-6 h-6 text-blue mx-auto" />}
                </>
              }
              {item.status === "COMPLETED_WITH_ERROR" && <ExclamationCircleIcon className="w-6 h-6 text-yellow mx-auto" />}
            </>
          }

          {actionStatus === "RERUNNING" &&
            <div className="text-gray-400">
              Rerunning...
            </div>
          }
        </td>

        {/* Created at */}
        <td className="text-right text-gray-400 h-14 w-44">
          {actionStatus === "DELETED" && "Removed"}

          {actionStatus === "INITIAL" &&
            moment(props.item.createdAt).format("MMM D, h:mma")
          }
        </td>

        {/* Additional options (kebeb menu) */}
        <td className="h-14 w-8">
          {actionStatus === "INITIAL" &&
            <div className="flex justify-center relative">
              <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
                show={showCopiedMessage}
                unmount
              >
                <div className="absolute bg-blue whitespace-nowrap text-white rounded top-0 right-44 z-10 shadow px-2 -pb-1 pointer-events-none">
                  Support code copied to clipboard
                </div>
              </Transition>
              <Popover className="relative" onClick={(e: React.MouseEvent) => e.stopPropagation()}>
                <Popover.Button>
                  <DotsVerticalIcon className="w-4 h-4 text-gray-400 hover:text-gray-600" />
                </Popover.Button>
                <Popover.Panel className="absolute mt-1 right-0 z-20 flex flex-col w-42 bg-white border rounded shadow overflow-y-auto">
                  {hasPermission(workflow.team, "workflows.run") &&
                    <Popover.Button className="flex items-center font-medium text-xs text-blue w-full px-4 py-2 hover:bg-blue-50 cursor-pointer whitespace-nowrap" onClick={handleRerunItem}>
                      <RefreshIcon className="w-4 h-4 mr-2" /> Rerun
                    </Popover.Button>
                  }
                  {hasPermission(workflow.team, "workflows.edit") && // TODO: Should this be workflows.run?
                    <Popover.Button className="flex items-center font-medium text-xs text-blue w-full px-4 py-2 hover:bg-blue-50 cursor-pointer whitespace-nowrap" onClick={handleDeleteItem}>
                      <TrashIcon className="w-4 h-4 mr-2" /> Delete
                    </Popover.Button>
                  }
                  <CopyToClipboard text={item.id} onCopy={handleOnCopySupport}>
                    <Popover.Button className="flex items-center font-medium text-xs text-blue w-full px-4 py-2 hover:bg-blue-50 cursor-pointer whitespace-nowrap">
                      <ClipboardCopyIcon className="w-4 h-4 mr-2" /> Copy Support Code
                    </Popover.Button>
                  </CopyToClipboard>
                  {(workflow.review && workflow.signatureConfig && item.stage === "SIGNATURES_PENDING") &&
                    <Popover.Button className="flex items-center font-medium text-xs text-blue w-full px-4 py-2 hover:bg-blue-50 cursor-pointer whitespace-nowrap" onClick={handleRescindItem}>
                      <BanIcon className="w-4 h-4 mr-2" /> Cancel Signature Requests
                    </Popover.Button>
                  }
                </Popover.Panel>
              </Popover>
            </div>
          }
        </td>
      </tr>

      {isExpanded &&
        <tr>
          <td colSpan={8} className="px-4">
            {/* Wrap table in div to apply padding since it can't be applied to table */}
            <div className="pb-2 bg-gray-100 shadow-inner rounded">
              <table className="w-full">
                {/* Define column widths */}
                <thead>
                  <tr>
                    {/* Chevron (24px) + source item id column (80px) - x-margin (16px) */}
                    <th className="w-[88px]" />
                    <th className="mx-auto" />
                    {/* Same as status column in outer table */}
                    <th className="w-44" />
                    {/* Created at */}
                    <td className="w-[192px]" />
                  </tr>
                </thead>

                <tbody>
                  {(!primaryOutput && item.status === "PENDING") &&
                    <tr>
                      <td />
                      <td colSpan={5} className="pt-3 text-sm font-semibold text-gray-600">
                        This automation item is currently being completed...
                      </td>
                    </tr>
                  }

                  {(!primaryOutput && item.status !== "PENDING") &&
                    <tr>
                      <td />
                      <td colSpan={4} className="pt-3 text-sm font-semibold text-gray-600">
                        An issue occured in the processing of this automation item, please re-run this item from the menu to the right.<br />
                        If the issue persists please contact support and provide the support code which you can copy from the same menu.
                      </td>
                      <td>
                        <ArrowRightIcon className="w-6 h-6 text-gray-600 -rotate-45 animate-pulse" />
                      </td>
                    </tr>
                  }

                  {documentOutputs.length > 0 &&
                    <>
                      <tr>
                        <td />
                        <td className="font-semibold text-xs text-gray-400 pt-2">
                          Documents
                        </td>
                        <td colSpan={2} />
                      </tr>
                      {documentOutputs.map(output =>
                        <tr key={output.id}>
                          <td />
                          <td>
                            <div className="flex items-center gap-2">
                              <img className="w-4 h-4" src={MIMETYPE_ICON_MAP[output.file?.mimeType ?? "application/vnd.google-apps.document"]} alt="Output file icon" />
                              <a className="group hover:underline hover:text-blue" href={output.file?.url} target="_blank" rel="noreferrer">
                                {output.file?.name}<ExternalLinkIcon className="hidden group-hover:inline-block w-4 h-4 ml-1 text-blue" />
                              </a>
                            </div>
                          </td>
                          <td className="text-sm text-center font-semibold text-green">
                            Completed
                          </td>
                          <td />
                        </tr>
                      )}
                    </>
                  }

                  {item.approvedBy &&
                    <>
                      <tr>
                        <td />
                        <td className="font-semibold text-xs text-gray-400 pt-2">
                          Review
                        </td>
                        <td colSpan={2} />
                      </tr>
                      <tr>
                        <td />
                        <td>
                          <div className="flex items-center gap-2">
                            <CheckCircleIcon className="w-4 h-4 text-green" />
                            <div className="">
                              Reviewed by <span className="font-semibold text-gray-600">{item.approvedBy.fullName}</span>
                            </div>
                          </div>
                        </td>
                        <td className="text-sm text-center font-semibold text-green">
                          Approved
                        </td>
                        <td className="text-sm text-right font-semibold text-gray-400 pr-4">
                          {moment(item.approvedAt).format("MMM D, h:mma")}
                        </td>
                      </tr>
                    </>
                  }

                  {pdfOuputs.length > 0 &&
                    <>
                      <tr>
                        <td />
                        <td className="font-semibold text-xs text-gray-400 pt-2">
                          PDFs
                        </td>
                        <td colSpan={2} />
                      </tr>
                      {pdfOuputs.map((output) =>
                        <tr key={output.id + "-pdf"}>
                          <td />
                          <td>
                            <div className="flex items-center gap-2">
                              <img className="w-4 h-4" src={MIMETYPE_ICON_MAP["application/pdf"]} alt="PDF icon" />
                              <a className="group hover:underline hover:text-blue" href={output.filePdf?.url} target="_blank" rel="noreferrer">
                                {output.filePdf?.name}<ExternalLinkIcon className="hidden group-hover:inline-block w-4 h-4 ml-1 text-blue" />
                              </a>
                            </div>
                          </td>
                          <td className="text-sm text-center font-semibold text-green">
                            Completed
                          </td>
                          <td />
                        </tr>
                      )}
                    </>
                  }

                  {microsoftOuputs.length > 0 &&
                    <>
                      <tr>
                        <td />
                        <td className="font-semibold text-xs text-gray-400 pt-2">
                          Microsoft Files
                        </td>
                        <td colSpan={2} />
                      </tr>
                      {microsoftOuputs.map((output) =>
                        <tr key={output.id + "-microsoft"}>
                          <td />
                          <td>
                            <div className="flex items-center gap-2">
                              <img className="w-4 h-4" src={MIMETYPE_ICON_MAP[output.fileMicrosoft?.mimeType!]} alt="PDF icon" />
                              <a className="group hover:underline hover:text-blue" href={output.fileMicrosoft?.url} target="_blank" rel="noreferrer">
                                {output.fileMicrosoft?.name}<ExternalLinkIcon className="hidden group-hover:inline-block w-4 h-4 ml-1 text-blue" />
                              </a>
                            </div>
                          </td>
                          <td className="text-sm text-center font-semibold text-green">
                            Completed
                          </td>
                          <td />
                        </tr>
                      )}
                    </>
                  }

                  {signatureRequests.length > 0 &&
                    <>
                      <tr>
                        <td />
                        <td className="font-semibold text-xs text-gray-400 pt-2">
                          Signature Requests
                        </td>
                        <td colSpan={2} />
                      </tr>
                      {signatureRequests.sort((a, b) => a.recipientNumber - b.recipientNumber).map((signatureRequest) =>
                        <tr key={signatureRequest.id}>
                          <td />
                          <td>
                            <div className="flex items-center gap-2">
                              {signatureRequest.recipientNumber === 0 &&
                                <DocumentTextIcon className="w-4 h-4 text-blue" />
                              }
                              {signatureRequest.recipientNumber > 0 &&
                                <>
                                  {signatureRequest.status === "PENDING" &&
                                    <DotsHorizontalIcon className="w-4 h-4 text-blue" />
                                  }
                                  {signatureRequest.status === "REQUESTED" &&
                                    <MailIcon className="w-4 h-4 text-blue" />
                                  }
                                  {signatureRequest.status === "ACCESSED" &&
                                    <MailOpenIcon className="w-4 h-4 text-blue" />
                                  }
                                  {signatureRequest.status === "COMPLETED" &&
                                    <BadgeCheckIcon className="w-4 h-4 text-blue" />
                                  }
                                  {signatureRequest.status === "ERROR" &&
                                    <ExclamationIcon className="w-4 h-4 text-red" />
                                  }
                                  {["CANCELED", "EXPIRED"].includes(signatureRequest.status) &&
                                    <BanIcon className="w-4 h-4 text-yellow" />
                                  }
                                </>
                              }
                              {signatureRequest.recipientNumber === 0
                                ? <div className="flex items-baseline gap-1 relative text-gray-600 hover:underline hover:text-blue cursor-pointer" onClick={() => window.open(signatureRequest.publicTokenUrl, "_blank")}>Preview Document <ExternalLinkIcon className="w-4 h-4 flex-shrink-0 top-0.5 relative" /></div>
                                : <div className="text-gray-600">{signatureRequest.recipientEmail}</div>
                              }
                              {(["REQUESTED", "ACCESSED"].includes(signatureRequest.status) && signatureRequest.expiry) &&
                                <div className="text-xs text-gray-600 flex items-center gap-1 ml-4">
                                  <ClockIcon className="w-4 h-4" />
                                  Expires {moment(signatureRequest.expiry).fromNow()}
                                </div>
                              }
                              {(signatureRequest.recipientNumber > 0 && (signatureRequest.status === "REQUESTED" || signatureRequest.status === "ACCESSED")) &&
                                <CopyToClipboard text={signatureRequest.publicTokenUrl} onCopy={() => handleOnCopiedSignatureRequest(signatureRequest.recipientNumber)}>
                                  {copiedSignatureRequest === signatureRequest.recipientNumber
                                    ? <div className="flex items-center gap-1 text-xs text-blue ml-2 font-semibold select-none"> Signature Request Link Copied to Clipboard</div>
                                    : <div className="flex items-center gap-1 text-xs text-gray-600 ml-2 hover:underline cursor-pointer"><ClipboardCopyIcon className="w-4 h-4 flex-shrink-0" /> Copy Signature Request Link</div>
                                  }
                                </CopyToClipboard>
                              }
                            </div>
                            {signatureRequest.trackingPixel?.events.map((event: TrackingPixelEvent) =>
                              <div key={event.id} className="text-xs text-gray-600 flex items-center gap-1 ml-6">
                                <EyeIcon className="w-4 h-4 flex-shrink-0" /> {moment(event.createdAt).format("MMM D, h:mma")} ({moment(event.createdAt).fromNow()})
                              </div>
                            )}
                          </td>
                          <td className="text-sm text-center font-semibold">
                            {signatureRequest.recipientNumber > 0 &&
                              <>
                                {signatureRequest.status === 'PENDING' &&
                                  <div>Pending...</div>
                                }
                                {signatureRequest.status === 'REQUESTED' &&
                                  <div className="text-blue">Requested</div>
                                }
                                {signatureRequest.status === 'ACCESSED' &&
                                  <div className="text-blue">Accessed</div>
                                }
                                {signatureRequest.status === 'COMPLETED' &&
                                  <div className="text-green">Completed</div>
                                }
                                {signatureRequest.status === 'ERROR' &&
                                  <div className="text-yellow">Failed</div>
                                }
                                {signatureRequest.status === 'CANCELED' &&
                                  <div className="text-yellow">Canceled</div>
                                }
                              </>
                            }
                          </td>
                          <td>
                            {(signatureRequest.status === "REQUESTED" || signatureRequest.status === "ACCESSED") &&
                              <>
                                {resendStatus === "INITIAL" &&
                                  <button className="btn btn-blue text-xs ml-auto mr-6 h-6 w-32" onClick={(e) => { e.stopPropagation(); handleResendSignatureRequest(signatureRequest.id); }}>Resend</button>
                                }
                                {resendStatus === "SENDING" &&
                                  <button className="btn btn-blue text-xs ml-auto mr-6 h-6 w-32" disabled>Sending...</button>
                                }
                                {resendStatus === "RESENT" &&
                                  <button className="btn btn-green text-xs cursor-not-allowed ml-auto mr-6 h-6 w-32">Resent</button>
                                }
                                {resendStatus === "ERROR" &&
                                  <button className="btn btn-red text-xs cursor-not-allowed ml-auto mr-6 h-6 w-32">Error</button>
                                }
                              </>
                            }
                          </td>
                        </tr>
                      )}
                    </>
                  }

                  {outputEmails.length > 0 &&
                    <>
                      <tr>
                        <td />
                        <td className="font-semibold text-xs text-gray-400 pt-2">
                          Emails
                        </td>
                        <td colSpan={2} />
                      </tr>
                      {outputEmails.map((email) =>
                        <tr key={email.id}>
                          <td />
                          <td>
                            <div className="flex flex-col gap-1">
                              <div className="flex items-center gap-2">
                                <img className="w-4 h-4" src={BLOCK_ICON_MAP[email.emailType]} alt="Email icon" />
                                <a className="group hover:underline hover:text-blue" href={email.emailType === "GMAIL" ? "https://mail.google.com/mail/u/0/#sent/" + email.messageId : "https://outlook.office365.com/mail/sentitems"} target="_blank" rel="noreferrer">
                                  {email.emailSubject}<ExternalLinkIcon className="hidden group-hover:inline-block w-4 h-4 ml-1 text-blue" />
                                </a>
                              </div>
                              <div className={`ml-4 text-sm flex items-center gap-2 ${email.errorField === "email_to" ? "text-yellow font-semibold" : "text-black"}`}>
                                {email.errorField === "email_to"
                                  ? <ExclamationIcon className="text-yellow flex-shrink-0 w-4 h-4" />
                                  : <ExclamationIcon className="opacity-0 flex-shrink-0 w-4 h-4" />
                                }
                                <span className="font-semibold text-gray-600">To:</span> {email.emailTo || <span className={`font-semibold ${email.errorField === "email_to" ? "text-yellow" : "text-gray-400"}`}>{"<blank>"}</span>}
                              </div>
                              {email.emailCc &&
                                <div className={`ml-4 text-sm flex items-center gap-2 ${email.errorField === "email_cc" ? "text-yellow font-semibold" : "text-black"}`}>
                                  {email.errorField === "email_cc"
                                    ? <ExclamationIcon className="text-yellow flex-shrink-0 w-4 h-4" />
                                    : <ExclamationIcon className="opacity-0 flex-shrink-0 w-4 h-4" />
                                  }
                                  <span className="font-semibold text-gray-600">Cc:</span> {email.emailCc}
                                </div>
                              }
                              {email.emailBcc &&
                                <div className={`ml-4 text-sm flex items-center gap-2 ${email.errorField === "email_bcc" ? "text-yellow font-semibold" : "text-black"}`}>
                                  {email.errorField === "email_bcc"
                                    ? <ExclamationIcon className="text-yellow flex-shrink-0 w-4 h-4" />
                                    : <ExclamationIcon className="opacity-0 flex-shrink-0 w-4 h-4" />
                                  }
                                  <span className="font-semibold text-gray-600">Bcc:</span> {email.emailBcc}
                                </div>
                              }
                              {(email.status === "FAILED" && email.errorField === "__rate_limit") &&
                                <div className="ml-4 text-sm flex items-center gap-2">
                                  <ExclamationIcon className="text-yellow flex-shrink-0 w-4 h-4" />
                                  <span className="text-yellow font-semibold">Rate limit exceeded, please try again later</span>
                                </div>
                              }
                              {(email.status === "FAILED" && !email.errorField) &&
                                <div className="ml-4 text-sm flex items-center gap-2">
                                  <ExclamationIcon className="text-yellow flex-shrink-0 w-4 h-4" />
                                  <span className="text-yellow font-semibold">An unknown error occured</span>
                                </div>
                              }
                            </div>
                            {email.trackingPixel?.events.map((event: TrackingPixelEvent) =>
                              <div key={event.id} className="text-xs text-gray-600 flex items-center gap-1 ml-6">
                                <EyeIcon className="w-4 h-4 flex-shrink-0" /> {moment(event.createdAt).format("MMM D, h:mma")} ({moment(event.createdAt).fromNow()})
                              </div>
                            )}
                          </td>
                          <td className="text-sm text-center justify-start flex flex-col font-semibold">
                            {email.status === 'PENDING' &&
                              <div>Pending...</div>
                            }
                            {email.status === 'SENT' &&
                              <div className="text-green">Sent</div>
                            }
                            {email.status === 'FAILED' &&
                              <div className="text-yellow">Failed</div>
                            }
                          </td>
                          <td />
                        </tr>
                      )}
                    </>
                  }

                  {item.stripePaymentLink &&
                    <>
                      <tr>
                        <td />
                        <td className="font-semibold text-xs text-gray-400 pt-2">
                          Stripe Payment Link
                        </td>
                        <td colSpan={2} />
                      </tr>
                      {[{ id: "0" }].map((data: { id: string }) =>
                        <tr key={data.id}>
                          <td />
                          <td>
                            <div className="flex items-center gap-2">
                              <img className="h-4 w-4 rounded" src=" https://static.portant.co/stripe-logo.png" alt="Stripe" />
                              A payment link was created for this automation item
                            </div>
                          </td>
                          <td colSpan={2} className="text-sm text-center font-semibold">
                            {/* TODO: This is hacky as it uses the signature request state, improve this */}
                            <CopyToClipboard text={item.stripePaymentLink!.url} onCopy={() => handleOnCopiedSignatureRequest(0)}>
                              {copiedSignatureRequest === 0
                                ? <div className="flex items-center gap-1 text-xs text-blue font-semibold select-none">Payment link copied to clipboard</div>
                                : <div className="flex items-center gap-1 text-xs text-gray-600 ml-5 hover:underline cursor-pointer"><ClipboardCopyIcon className="w-4 h-4 flex-shrink-0" />Copy payment link</div>
                              }
                            </CopyToClipboard>
                          </td>
                        </tr>
                      )}
                    </>
                  }

                </tbody>
              </table>
            </div>
          </td>
        </tr>
      }
    </>
  );
}

export default WorkflowOutputsItem;


import { useEffect, useState } from "react";
import { Email } from "../interfaces";
import { ExclamationCircleIcon, TrashIcon } from "@heroicons/react/outline";

type Props = {
  email: Email,
  onChange: (field: string, value: any) => void,
  allowEditing: boolean
}

function WorkflowEmailTo(props: Props) {
  const [emptyTo, setEmptyTo] = useState(false);
  const [showBcc, setShowBcc] = useState(false);
  const [showCc, setShowCc] = useState(false);
  const { email, allowEditing, onChange } = props;

  const handleEmailTo = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setEmptyTo(Boolean(email.emailTo.length && !target.value.length));
    onChange('emailTo', target.value)
  }

  useEffect(() => {
    setShowCc(email.emailCc.length > 0);
    setShowBcc(email.emailBcc.length > 0);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="flex items-center w-full relative mt-2">
        <label className="w-20 font-gilroy font-medium text-gray-500" htmlFor="email-to" onClick={() => document.getElementById("email-to")?.focus()}>To</label>
        <input
          value={email.emailTo}
          onChange={handleEmailTo}
          placeholder="Type email addresses directly or insert {{Template Tags}}"
          className="w-full focus-visible:outline-none h-10 whitespace-nowrap overflow-hidden ml-2"
          id="email-to"
        />
        <div className="absolute right-0 flex justify-end text-gray-500 bg-white pl-1">
          {(!showCc && allowEditing) && <button className="mr-2 font-semibold" onClick={() => setShowCc(true)} type="button">Cc</button>}
          {(!showBcc && allowEditing) && <button className="font-semibold" onClick={() => setShowBcc(true)} type="button">Bcc</button>}
        </div>
      </div >

      {emptyTo &&
        <div className="flex text-yellow text-sm items-center mb-1" onClick={() => document.getElementById("email-to")?.focus()} >
          <ExclamationCircleIcon className="w-4 h-4 mr-1" />
          Please enter at least one recipient
        </div>
      }

      {showCc &&
        <div className="flex items-center w-full">
          <label className="w-20 font-gilroy font-medium text-gray-500" htmlFor="email-cc" onClick={() => document.getElementById("email-cc")?.focus()}>Cc</label>
          <div className="flex w-full items-center">
            <input
              value={email.emailCc}
              id="email-cc"
              onChange={({ target }) => onChange('emailCc', target.value)}
              className="w-full focus-visible:outline-none h-10 whitespace-nowrap overflow-hidden ml-2"
              disabled={!allowEditing}
            />
            <TrashIcon className="w-6 h-6 mx-2 cursor-pointer text-gray-500 hover:text-red" onClick={() => { setShowCc(false); onChange("emailCc", ""); }} />
          </div>
        </div>
      }

      {showBcc &&
        <div className="flex items-center w-full">
          <label className="w-20 font-gilroy font-medium text-gray-500" htmlFor="email-cc" onClick={() => document.getElementById("email-Bcc")?.focus()}>Bcc</label>
          <div className="flex w-full items-center">
            <input
              value={email.emailBcc}
              id="email-Bcc"
              onChange={({ target }) => onChange('emailBcc', target.value)}
              className="w-full focus-visible:outline-none h-10 whitespace-nowrap overflow-hidden ml-2"
              disabled={!allowEditing}
            />
            <TrashIcon className="w-6 h-6 mx-2 cursor-pointer text-gray-500 hover:text-red" onClick={() => { setShowBcc(false); onChange("emailBcc", ""); }} />
          </div>
        </div>
      }
    </>
  );
}

export default WorkflowEmailTo;

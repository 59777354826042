import Modal from "./Modal";

type Props = {
  switchToHTMLMode: () => void;
  pasteInPlainText: () => void;
}

const PasteContentModal = (props: Props) => {
  const { switchToHTMLMode, pasteInPlainText } = props;
  return (
    <Modal
      title="Do you want to paste the HTML code as plain text?"
      primaryButtonProps={{ onClick: switchToHTMLMode, text: 'Switch to HTML Source Mode' }}
      secondaryButtonProps={{ onClick: pasteInPlainText, text: 'Paste as Plain Text' }}
    >
      <p>
        It seems like you are trying to paste HTML code, but since the editor is set to rich text mode, we'll paste the code as plain text.
        Do you want to switch to HTML Source Mode?
      </p>
    </Modal>
  );
}

export default PasteContentModal;

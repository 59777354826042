import { useState } from "react";
import useUser from "../hooks/useUser";
import SmallSelectField from "./SmallSelectField";
import { patchData } from "../backend";
import { useModal } from "../contexts";
import ErrorModal from "./ErrorModal";

function SettingsLanguage() {
  const { user, refreshUser } = useUser();
  const { openModal } = useModal();

  const [language, setLanguage] = useState<string>(user?.language ?? "en");

  const [saveState, setSaveState] = useState<"INITIAL" | "PENDING" | "SAVING" | "SAVED">("INITIAL");

  async function handleSave() {
    setSaveState("SAVING");

    try {
      const responseUser = await patchData("/auth/me/", { language })
      await refreshUser(responseUser);

      setSaveState("SAVED");
    } catch {
      setSaveState("INITIAL");
      openModal(
        <ErrorModal
          details="An error has occurred while saving your settings. Please try again later."
          detailsClassName="text-left"
        />
      );
    }
  }

  return (
    <div className="flex flex-col p-8 w-full">
      <div className="flex items-end">
        <div className="flex flex-col">
          <div className="font-gilroy font-semibold text-lg">
            Language
          </div>
          <div className="text-gray-400 text-sm font-semibold">
            If you do not see your language as an option, please contact our support team to request having your language added.
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-4 mt-4">
        <SmallSelectField className="w-72" label="Language" value={language} onChange={(value) => { setLanguage(value); setSaveState("PENDING"); }}>
          <option value={"en"}>English</option>
          <option value={"es"}>Spanish</option>
          <option value={"fr"}>French</option>
          <option value={"de"}>German</option>
        </SmallSelectField>

        <button className="btn btn-blue text-sm w-40 px-2 py-1 transition-colors" disabled={saveState !== "PENDING"} onClick={handleSave}>
          {saveState === "SAVING" ? "Saving..." : (saveState === "SAVED" ? "Saved" : "Save")}
        </button>

      </div>

    </div>
  );
}

export default SettingsLanguage;

import { DatabaseIcon, DocumentTextIcon, ExternalLinkIcon, InformationCircleIcon, MailIcon, PencilIcon, TrashIcon, XIcon } from "@heroicons/react/outline";
import { useContext, useEffect, useState } from "react";
import { useModal, WorkflowContext } from "../contexts";
import { BLOCK_ICON_MAP, BLOCK_NAME_MAP, GOOGLE_TO_MICROSOFT_NAME_MAP, GOOGLE_TO_MICROSOFT_MIMETYPE_MAP, MIMETYPE_ICON_MAP } from "../utils";
import { Transition } from '@headlessui/react'
import ToggleButton from "./ToggleButton";
import GooglePickerButton from "./GooglePickerButton";
import ProLabel from "./ProLabel";
import WorkflowDocumentOutputName from "./WorkflowDocumentOutptName";
import WorkflowSourceFieldTable from "./WorkflowSourceFieldTable";
import WorkflowSelectModal from "./WorkflowSelectModal";
import { Document } from "../interfaces";
import { OAuthError, putData } from "../backend";
import useUser from "../hooks/useUser";
import WorkflowSignatureConfig from "./WorkflowSignatureConfig";
import PayWallModalPro from "./PayWallModalPro";
import moment from "moment-timezone";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import TransitionWorkflowPage from "./transitions/TransitionWorkflowPage";
import TransitionWorkflowPageSideBar from "./transitions/TransitionWorkflowPageSideBar";
import TransitionWorkflowPageContent from "./transitions/TransitionWorkflowPageContent";
import OAuthModal from "./OAuthModal";
import ErrorModal from "./ErrorModal";
import WorkflowPaymentConfig from "./WorkflowPaymentConfig";
import WorkflowDocumentPDF from "./WorkflowDocumentPDF";
import SmallSelectField from "./SmallSelectField";

function WorkflowDocument() {
  const { user, hasPermission } = useUser();
  const { openModal } = useModal();

  const history = useHistory();
  const location = useLocation();
  const { url } = useRouteMatch();

  const workflowContext = useContext(WorkflowContext)
  const { workflow, mutateWorkflow } = workflowContext;

  const [showOutputsOptions, setShowOutputsOptions] = useState(false);

  const index = (() => {
    const hash = location.hash.split("#")[1];

    if (hash === "signable")
      return workflow.documents.indexOf(workflow.documents.filter(d => d.isSignable)[0]);

    return (parseInt(hash) || 1) - 1;
  })();

  const initialDocument = workflow.documents[index] ?? workflow.documents[0];
  const [document, setDocument] = useState<Document>();

  const [showNextStepMessage, setShowNextStepMessage] = useState(false);

  const isMac = navigator.platform.toLowerCase().indexOf("mac") >= 0;

  function closeNextStepMessage() {
    const key = "showNextStepMessageMap";
    const showNextStepMessageMapString = localStorage.getItem(key) ?? "{}";
    const showNextStepMessageMap: Record<string, boolean> = JSON.parse(showNextStepMessageMapString);

    showNextStepMessageMap[document!.id] = false;
    localStorage.setItem(key, JSON.stringify(showNextStepMessageMap));

    setShowNextStepMessage(false);
  }

  useEffect(() => {
    setDocument(initialDocument);

    // Check if the document was created before the "next step message" was introduced
    if (!initialDocument || moment(initialDocument.createdAt) < moment("2023-01-18"))
      return;

    const key = "showNextStepMessageMap";
    const showNextStepMessageMapString = localStorage.getItem(key) ?? "{}";
    const showNextStepMessageMap: Record<string, boolean> = JSON.parse(showNextStepMessageMapString);

    setShowNextStepMessage(showNextStepMessageMap[initialDocument.id] ?? true);
  }, [initialDocument, showOutputsOptions]);

  async function updateDocument(field: string, value: any) {
    if (!document)
      return;

    const updatedDocument = { ...document, [field]: value };
    setDocument(updatedDocument);

    await putData(`/workflows/documents/${document!.id}/`, updatedDocument)
      .then(setDocument)
      .then(() => mutateWorkflow())
      .catch(console.error);
  }

  async function handleChangeFile(file: any) {
    try {
      await putData(`/workflows/documents/${document!.id}/file/`, { file })
    } catch (error) {
      if (error instanceof OAuthError)
        openModal(<OAuthModal oauthUrl={error.oauthUrl} />)
      else
        openModal(<ErrorModal details={error} />)
    }

    await mutateWorkflow();
  }

  function toggleRemoveBranding(flag: boolean) {
    if (!user?.subscription?.featureRemoveBranding) {
      openModal(<PayWallModalPro />)
      return;
    }

    updateDocument("removeBranding", flag);
  }

  if (document && document.documentType === "PDF_FILLABLE") {
    if (!location.pathname.endsWith("/document"))
      return null;

    return <WorkflowDocumentPDF />;
  }

  if (!document) {
    if (!location.pathname.endsWith("/document"))
      return null;

    return (
      <div className="flex flex-col items-center justify-center gap-4 h-full w-full">
        <div className="font-semibold font-gilroy text-gray-600 text-center w-96">
          This workflow contains no document templates, would you like to add some?
        </div>
        <button className="btn btn-blue" onClick={() => openModal(<WorkflowSelectModal title="Add Workflow Template" subtitle="Add a template to your workflow" showDocuments workflowContext={workflowContext} />)}>
          Add Document Template
        </button>
      </div>
    );
  }

  return (
    <TransitionWorkflowPage show={location.pathname.endsWith("/document")}>
      {hasPermission(workflow.team, "workflows.edit") &&
        <TransitionWorkflowPageSideBar>
          {!!workflow.source && <>

            {showNextStepMessage &&
              <div className="relative flex flex-col gap-1 p-2 text-sm text-white bg-blue rounded shadow">
                <div className="flex items-center font-gilroy font-semibold">
                  <InformationCircleIcon className="w-4 h-4 mr-2" />Next Step
                </div>
                <div className="text-xs font-semibold">
                  Click 'Copy' in the source table below then place your cursor in the document and click {isMac ? "⌘" : "Ctrl-"}V to insert the tag into your document.
                </div>
                <XIcon className="absolute w-4 h-4 top-1 right-1 cursor-pointer" onClick={closeNextStepMessage} />
              </div>
            }

            <WorkflowSourceFieldTable workflow={workflow} mutateWorkflow={mutateWorkflow} document={document} />

            <WorkflowDocumentOutputName initialValue={document.outputName} onEnter={(value) => updateDocument("outputName", value)} />

            <div className="flex justify-start items-center font-gilroy font-semibold text-sm w-full">
              <ToggleButton value={document.isSignable || document.createPdfCopy} onChange={(flag) => updateDocument(document.isSignable ? "isSignable" : "createPdfCopy", flag)} />
              <img className="w-5 h-5 ml-2 mr-1" src={MIMETYPE_ICON_MAP["application/pdf"]} alt="PDF File Icon" />
              <div className="font-semibold text-sm text-gray-600">Create {document.isSignable ? "Signable PDF" : "PDF copy"}</div>
              <div className={`ml-auto text-sm font-semibold cursor-pointer hover:underline ${showOutputsOptions ? "text-gray-600" : "text-gray-400 hover:text-gray-600"}`} onClick={() => setShowOutputsOptions(f => !f)}>
                More options...
              </div>
            </div>

            {/* NOTE: Unused advanced options header */}
            {/* <div className="flex text-sm font-semibold items-center text-gray-600 cursor-pointer" onClick={() => setShowOutputsOptions(f => !f)}>
              <ChevronRightIcon className={`w-4 h-4 mr-1 ${showOutputsOptions ? "rotate-90" : "rotate-0"}`} /> Advanced Output Options
            </div> */}

            <Transition className="flex flex-col gap-4"
              show={showOutputsOptions}
              enter="transition-opacity duration-150"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-150"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="flex justify-start items-center font-gilroy font-semibold text-sm w-full">
                <ToggleButton value={document.createMicrosoftCopy} onChange={(flag) => updateDocument("createMicrosoftCopy", flag)} />
                <img className="w-5 h-5 ml-2 mr-1" src={MIMETYPE_ICON_MAP[GOOGLE_TO_MICROSOFT_MIMETYPE_MAP[document.documentType]]} alt="Microsoft File Icon" />
                <div className="font-semibold text-sm text-gray-600">Create {GOOGLE_TO_MICROSOFT_NAME_MAP[document.documentType]} copy</div>
              </div>

              {workflow.source.sourceType === "HUBSPOT" &&
                <div className="flex justify-start items-start font-gilroy font-semibold text-sm  w-full">
                  <ToggleButton value={(document.isSignable || document.createPdfCopy || document.createMicrosoftCopy) && document.uploadToHubspot} disabled={!(document.isSignable || document.createPdfCopy || document.createMicrosoftCopy)} onChange={(flag) => updateDocument("uploadToHubspot", flag)} />
                  <div className="flex flex-col gap-1 mt-0.5 ml-2 mr-1">
                    <div className={`font-semibold text-sm ${(document.isSignable || document.createPdfCopy || document.createMicrosoftCopy) ? "text-gray-600" : "text-gray-400"}`}>
                      Upload PDF/MS copy to HubSpot
                    </div>
                    {(document.uploadToHubspot && (document.isSignable || document.createPdfCopy || document.createMicrosoftCopy)) &&
                      <SmallSelectField label="File Access" value={document.uploadToHubspotAccess} onChange={(value) => updateDocument("uploadToHubspotAccess", value)}>
                        <option value="PRIVATE">Private</option>
                        <option value="PUBLIC_INDEXABLE">Public (Indexable)</option>
                        <option value="PUBLIC_NOT_INDEXABLE">Public (Not Indexable)</option>
                      </SmallSelectField>
                    }
                  </div>
                </div>
              }

              {/* {(document.isSignable || document.createPdfCopy || document.createMicrosoftCopy) && */}
              <div className="flex justify-start items-center font-gilroy font-semibold text-sm  w-full">
                <ToggleButton value={document.removeOutput} disabled={!(document.isSignable || document.createPdfCopy || document.createMicrosoftCopy)} onChange={(flag) => updateDocument("removeOutput", flag)} />
                <div className={`font-semibold text-sm ml-2 mr-1 ${(document.isSignable || document.createPdfCopy || document.createMicrosoftCopy) ? "text-gray-600" : "text-gray-400"}`}>
                  Remove {BLOCK_NAME_MAP[document.documentType]} output file
                </div>
              </div>
              {/* } */}
            </Transition>

            {/* {(document.isSignable || document.createPdfCopy) && */}
            <div className="flex justify-start items-center font-gilroy font-semibold text-sm  w-full">
              {/* TODO: Open paywall or toggle setting */}
              <ToggleButton value={!!user?.subscription?.featureRemoveBranding && document.removeBranding} disabled={!(document.isSignable || document.createPdfCopy)} onChange={toggleRemoveBranding} />
              <div className={`font-semibold text-sm ml-2 mr-1 ${(document.isSignable || document.createPdfCopy) ? "text-gray-600" : "text-gray-400"}`}>
                Remove Portant PDF branding
              </div>
              <ProLabel />
            </div>
            {/* } */}

            {["GOOGLE_DOCS", "GOOGLE_SLIDES"].includes(document.documentType) &&
              <>
                <hr />
                <WorkflowPaymentConfig document={document} />
                <hr />
                <WorkflowSignatureConfig document={document} updateDocument={updateDocument} />
              </>
            }

            {workflow.documents.length === 1 && <>
              <hr />
              <div className="flex flex-col gap-2">
                <div className="flex flex-col gap-1">
                  <div className="flex items-center font-gilroy font-semibold text-gray-600">
                    <DocumentTextIcon className="w-6 h-6 mr-1" /> Add another document
                  </div>
                  <div className="font-semibold text-xs text-gray-400">
                    Create multiple documents in the same workflow
                  </div>
                </div>
                <button className="btn btn-white w-40 text-xs" onClick={() => openModal(<WorkflowSelectModal title="Add document" subtitle="Add another document to this workflow" showDocuments workflowContext={workflowContext} />)}>
                  Add
                </button>
              </div>
            </>}

            {workflow.emails.length === 0 && <>
              <hr />
              <div className="flex flex-col gap-2">
                <div className="flex flex-col gap-1">
                  <div className="flex items-center font-gilroy font-semibold text-gray-600">
                    <MailIcon className="w-6 h-6 mr-1" /> Share document via Gmail
                  </div>
                  <div className="font-semibold text-xs text-gray-400">
                    Automatically share created documents
                  </div>
                </div>
                <button className="btn btn-white w-40 text-xs" onClick={() => openModal(<WorkflowSelectModal title="Add sharing block" subtitle="Automatically share created documents" showEmails workflowContext={workflowContext} />)}>
                  Add
                </button>
              </div>
            </>}

          </>}

          {!workflow.source && <>
            <div className="flex flex-col gap-4 justify-center h-full">
              <div className="flex items-center">
                <DatabaseIcon className="w-6 h-6 mr-2" />
                <div className="font-gilroy font-semibold text-lg">
                  Connect a Source
                </div>
              </div>
              <div className="flex flex-col gap-2 font-semibold text-gray-600">
                Create a batch of documents from a spreadsheet
                <div className="flex items-center gap-2">
                  <div className="h-px bg-gray-600 w-full" />
                  <div className="flex-shrink-0">or</div>
                  <div className="h-px bg-gray-600 w-full" />
                </div>
                Auto-Create documents from an event or form&nbsp;submission
              </div>
              <button className="btn btn-blue" onClick={() => openModal(<WorkflowSelectModal title="Select Source" subtitle="Create or select a source where your data will come from" showSources workflowContext={workflowContext} />)}>
                Connect
              </button>
            </div>
          </>}
        </TransitionWorkflowPageSideBar>
      }

      <TransitionWorkflowPageContent>
        <div className="flex w-full items-center mb-2">
          <div className="flex justify-center items-center my-auto mr-2 rounded w-10 h-10">
            <img className="h-10 w-10 rounded-md" src={BLOCK_ICON_MAP[document.documentType]} alt="Google File Icon" />
          </div>
          <div className="flex flex-col h-full justify-center">
            <div className="flex text-sm font-semibold font-gilroy cursor-pointer hover:text-blue hover:underline" onClick={() => window.open(document.file?.url, "_blank")}>
              <div className="truncate max-w-[400px]">
                {document.file?.name}
              </div>
              {document.file?.url &&
                <ExternalLinkIcon className="h-4 w-4 ml-1 mb-1 group-hover:text-blue" />
              }
            </div>
            {hasPermission(workflow.team, "workflows.edit") &&
              <div className="flex gap-2 w-full">
                <GooglePickerButton className="flex items-center text-gray-500 text-sm font-gilroy font-medium hover:underline hover:text-gray-600" blockType={document.documentType} onPicked={handleChangeFile}>
                  <PencilIcon className="h-4 mr-1" /><>Change selected file</>
                </GooglePickerButton>
                {false && workflow.documents.length > 1 &&
                  <div className="flex items-center text-gray-500 text-sm font-gilroy font-medium cursor-pointer hover:underline hover:text-red" onClick={() => alert("Not implemented")}>
                    <TrashIcon className="h-4 mr-1" /><>Remove block</>
                  </div>
                }
              </div>
            }
          </div>
          <XIcon className="w-6 h-6 ml-auto text-gray-400 hover:text-black cursor-pointer" onClick={() => history.push(url)} />
        </div>
        {document.file
          ? <iframe title="Google Editor" className="w-full h-full border border-gray-300 rounded overflow-hidden" src={`${document.file!.url}?widget&rm=embedded`} />
          : <div className="m-auto">No file selected</div>
        }
      </TransitionWorkflowPageContent>
    </TransitionWorkflowPage >
  );

}

export default WorkflowDocument;

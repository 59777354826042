import { useState } from 'react';
import { useModal } from '../contexts';
import TextInputField from './TextInputField';
import IconPickerField from './IconPickerField';
import ColorPickerField from './ColorPickerField';
import Modal from './Modal';
import ErrorModal from "./ErrorModal";
import LoadingModal from "./LoadingModal";
import { OAuthError, postData } from '../backend';
import { useHistory } from 'react-router-dom';
import { Workflow } from '../interfaces';
import OAuthModal from './OAuthModal';
import useSWR from 'swr';
import ToggleButton from './ToggleButton';

interface Props {
  workflow: Workflow;
}

function WorkflowModalDuplicate(props: Props) {
  const history = useHistory();
  const { openModal, closeModal } = useModal();

  const { mutate: mutateWorkflowList } = useSWR("/workflows/");

  const [workflow, setWorfklow] = useState(props.workflow);
  const [duplicateFiles, setDuplicateFiles] = useState(true);

  async function handleClickCreate() {
    openModal(<LoadingModal title="Duplicating Workflow..." subtitle="This may take some time as we copy over the documents In your Google&nbsp;Drive." />);

    try {
      const newWorkflow = await postData(`/workflows/${props.workflow.id}/duplicate/`, { ...workflow, duplicateFiles });
      mutateWorkflowList();
      closeModal();

      history.push(`/w/${newWorkflow.id}`);
    } catch (error) {
      if (error instanceof OAuthError)
        openModal(<OAuthModal oauthUrl={error.oauthUrl} />)
      else
        openModal(<ErrorModal details={error} />)
    }
  }

  return (
    <Modal
      title="Duplicate Workflow"
      secondaryButtonProps={{ text: "Cancel", onClick: closeModal }}
      primaryButtonProps={{ text: "Create", onClick: handleClickCreate }}
    >
      <TextInputField label="Workflow Name" value={workflow.name} onChange={(name) => setWorfklow((w: any) => ({ ...w, name }))} required />
      <div className="flex justify-start items-center font-gilroy font-semibold text-sm w-full">
        <ToggleButton value={duplicateFiles} onChange={setDuplicateFiles} />
        <div className='flex flex-col'>

          <div className={`font-semibold text-sm ml-2 mr-1`}>
            Duplicate Google Drive files
          </div>
          <div className={`font-semibold text-gray-600 text-sm ml-2 mr-1`}>
            Create a new copy of any template or source files used in this workflow
          </div>
        </div>
      </div>
      <div className='flex flex-col gap-1'>
        <label className="flex flex-row font-gilroy font-semibold text-gray-600">Appearance</label>
        <div className="flex gap-16">
          <ColorPickerField value={workflow.color} onChange={(color) => setWorfklow((w: any) => ({ ...w, color }))} />
          <div className='flex flex-grow gap-4'>
            <IconPickerField value={workflow.icon} onChange={(icon) => setWorfklow((w: any) => ({ ...w, icon }))} selectColour={workflow.color} />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default WorkflowModalDuplicate;

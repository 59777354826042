import { SearchIcon } from "@heroicons/react/outline";
import { HubspotAppObject } from "../interfaces";
import { Dispatch, SetStateAction, useContext, useState } from "react";
import { HubspotAppContext } from "../contexts";

interface Props {
  selectedObjects: Record<string, boolean> | undefined;
  setSelectedObjects: Dispatch<SetStateAction<Record<string, boolean>>>;
}

function AppHubspotObjects(props: Props) {
  const [filterValue, setFilterValue] = useState<string>("");

  const { selectedObjects } = props;
  const { pickerData, currentAutomation } = useContext(HubspotAppContext);

  const associatedObjects = pickerData?.associatedObjectOptions;

  const active = currentAutomation?.status === "PENDING" || currentAutomation?.status === "RUNNING";

  function handleToggleSelectedObject(id: string) {
    if (active || id === pickerData.sourceObjectCompoundId)
      return

    props.setSelectedObjects((prev) => {
      if (prev === undefined)
        return { [id]: true };

      const newValue = !prev[id];
      return { ...prev, [id]: newValue };
    });
  }

  function handleToggleAll(objectType: string, checked: boolean) {
    props.setSelectedObjects((prev) => {
      if (prev === undefined)
        prev = {};

      for (const id of Object.keys(prev)) {
        if (id.startsWith(objectType + "."))
          prev[id] = checked;
      }

      return { ...prev };
    });
  }

  function handleRemoveOthers(objectType: string) {
    if (active)
      return;

    handleToggleAll(objectType, false);

    const objList = associatedObjects!.find((objList) => objList.toId === objectType)!;
    const filteredObjects = objList.items.filter(a => a.label.toLocaleLowerCase().includes(filterValue.toLocaleLowerCase()))

    for (const obj of filteredObjects)
      handleToggleSelectedObject(`${objList.toId}.${obj.id}`);

  }

  function isAllSelected(objectType: string): boolean {
    if (!associatedObjects)
      return false;

    return !!associatedObjects
      .find((objList) => objList.toId === objectType)?.items
      .every((obj) => selectedObjects?.[`${objectType}.${obj.id}`]);
  }

  return (
    <>
      {associatedObjects !== undefined &&
        <div className="font-semibold font-gilroy text-sm mb-4">
          {associatedObjects !== null
            ? <span className="text-gray-600">Select associated objects for this automation</span>
            : <span className="text-yellow">Unable to fetch associated objects. The automation can still be started as if all associated objects were selected.</span>
          }
        </div>
      }
      {(associatedObjects?.flatMap((objLit) => objLit.items)?.length ?? 0) > 20 &&
        <div className="relative mb-4">
          <SearchIcon className="h-4 w-4 text-gray-400 absolute left-1 top-1.5" />
          <input className="w-full border rounded pl-6 text-sm py-1 font-gilroy" type="text" value={filterValue} onChange={(e) => setFilterValue(e.currentTarget.value)} />
        </div>
      }
      <div className="flex flex-col overflow-y-auto gap-4 h-full mb-4">
        {associatedObjects === undefined &&
          <div className="flex items-center">
            <img src="https://static.portant.co/portant-loading-gray.svg" alt="Loading Logo" className='w-32 h-32' />
            <div className="text-gray-400 font-semibold text-center text-sm mt-2">Loading associated objects</div>
          </div>
        }
        {associatedObjects?.length === 0 &&
          <div className="text-gray-400 font-semibold text-sm w-[300px]">No associated objects to select from, the automation can be started as is.</div>
        }
        {associatedObjects?.map((objList) => (
          <div key={objList.toId}>
            <div className="flex items-center gap-2 border border-white rounded p-1 cursor-default">
              <input type="checkbox" checked={isAllSelected(objList.toId)} disabled={active || (filterValue.length > 0) || pickerData.sourceObjectType === objList.toId} onChange={() => handleToggleAll(objList.toId, !isAllSelected(objList.toId))} />
              <div className="text-sm text-black font-semibold font-gilroy underline">{objList.title}</div>
              <div className="text-xs text-gray-400 font-semibold ml-auto mr-1">{Object.entries(selectedObjects ?? {}).filter(([k, v]) => v && k.startsWith(objList.toId)).length} / {objList.items.length}</div>
            </div>
            {objList.items.filter(a => a.label.toLocaleLowerCase().includes(filterValue.toLocaleLowerCase())).length === 0 &&
              <div className="text-xs font-semibold text-gray-400 text-center mt-1">
                No {objList.title} match this search
              </div>
            }
            <div className="flex flex-col gap-1 mr-2">
              {objList.items.filter(a => a.label.toLocaleLowerCase().includes(filterValue.toLocaleLowerCase())).sort((a, b) => a.label.localeCompare(b.label)).map((obj: HubspotAppObject) => (
                <div key={obj.id} className="flex items-center gap-2 border rounded p-1 hover:bg-blue-50 cursor-pointer" onClick={() => handleToggleSelectedObject(`${objList.toId}.${obj.id}`)}>
                  <input type="checkbox" checked={!!(selectedObjects?.[`${objList.toId}.${obj.id}`])} onChange={(e) => { handleToggleSelectedObject(`${objList.toId}.${obj.id}`); e.stopPropagation(); }} onClick={(e) => { e.stopPropagation(); }} disabled={active || `${objList.toId}.${obj.id}` === pickerData.sourceObjectCompoundId} />
                  <div className="flex justify-between items-center w-full">
                    <div className="text-sm text-gray-600 font-semibold font-gilroy">{obj.label}</div>
                    <div className="text-xs text-gray-400 font-semibold">{obj.email || obj.id}</div>
                  </div>
                </div>
              ))}
            </div>
            {isAllSelected(objList.toId) && filterValue.length > 0 && objList.items.length > 1 && objList.items.filter(a => a.label.toLocaleLowerCase().includes(filterValue.toLocaleLowerCase())).length > 0 &&
              <div className="text-xs font-semibold text-gray-400 hover:text-blue cursor-pointer underline text-center mt-2" onClick={() => handleRemoveOthers(objList.toId)}>
                Unselect all others
              </div>
            }
          </div>
        ))
        }
      </div>
    </>
  );
}

export default AppHubspotObjects;

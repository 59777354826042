
import { useState } from "react";
import { Email } from "../interfaces";
import { ExclamationCircleIcon } from "@heroicons/react/outline";

type Props = {
  email: Email,
  onChange: (field: string, value: any) => void,
}

function WorkflowEmailSubject(props: Props) {
  const [emptySubjectField, setEmptySubjectField] = useState(false);
  const { email, onChange } = props;

  const handleEmailSubject = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setEmptySubjectField(Boolean(email.emailSubject.length && !target.value.length));
    onChange('emailSubject', target.value);
  }

  return (
    <>
      <div className="flex items-center w-full mt-2">
        <label className="w-20 font-gilroy font-medium text-gray-500" htmlFor="email-subject" onClick={() => document.getElementById("email-subject")?.focus()}>Subject</label>
        <input
          value={email.emailSubject}
          onChange={handleEmailSubject}
          className="w-full focus-visible:outline-none h-10 whitespace-nowrap overflow-hidden ml-2"
          id='email-subject'
        />
      </div>
      {emptySubjectField &&
        <div className="flex text-yellow text-sm items-center mb-1" onClick={() => document.getElementById("email-subject")?.focus()}>
          <ExclamationCircleIcon className="w-4 h-4 mr-1" />
          Please provide a subject
        </div>
      }
    </>
  );
}

export default WorkflowEmailSubject;

import { useContext } from "react";
import { WorkflowContext, useModal } from "../contexts";
import { BLOCK_ICON_MAP, capitalize } from "../utils";
import { CheckIcon, CollectionIcon, ExclamationCircleIcon, ExternalLinkIcon, FilterIcon } from "@heroicons/react/outline";
import { Source } from "../interfaces";
import useUser from "../hooks/useUser";
import WorkflowSourceConditionModal from "./WorkflowSourceConditionModal";
import { useHistory, useRouteMatch } from "react-router-dom";

function WorkflowOverviewBlockSource(props: { block: Source }) {
  const { block } = props;

  const { user } = useUser();
  const { openModal } = useModal();
  const { workflow, mutateWorkflow } = useContext(WorkflowContext);

  const history = useHistory();
  const { url } = useRouteMatch();

  function openWorkflowPage() {
    history.push(url + "/source")
  }

  const isValidBlock = (() => {
    if (block.sourceType === "GOOGLE_SHEETS" || block.sourceType === "GOOGLE_FORMS")
      return !!block.file;

    if (block.sourceType === "WEBHOOK")
      return block.sourceFields.length > 0;

    if (block.sourceType === "TALLY" || block.sourceType === "GLIDE" || block.sourceType === "ZAPIER")
      return (block.webhookConfig?.events?.length ?? 0) > 0;

    if (block.sourceType === "MICROSOFT_EXCEL")
      return !!block.microsoftExcelConfig?.fileId;

    if (block.sourceType === "HUBSPOT")
      return !!user?.hubspotCredentials?.hubspotUserId;

    if (block.sourceType === "COPPER")
      return !!user?.copperCredentials?.copperAccessToken;

    if (block.sourceType === "XERO")
      return !!user?.xeroCredentials?.id;
  })();

  return (
    <button id={block.id} className={`relative flex flex-shrink-0 items-center justify-start px-8 py-8 mb-5 bg-white border border-gray-300 cursor-pointer group rounded shadow w-[600px]`} onClick={openWorkflowPage}>
      {isValidBlock
        ? <CheckIcon className="flex-shrink-0 w-6 h-6 mr-8 p-1 bg-green text-white rounded-full" />
        : <ExclamationCircleIcon className="flex-shrink-0 w-6 h-6 mr-8 bg-yellow text-white rounded-full" />
      }
      <img src={BLOCK_ICON_MAP[block.sourceType]} alt="icon" className="w-12 h-12 flex-shrink-0 mr-8" />
      <div className="flex flex-col w-full overflow-hidden mr-4">
        <p className="text-sm text-gray-400 truncate text-start">
          Source
        </p>
        <div className="flex gap-2 items-center font-gilroy font-semibold">
          {!isValidBlock && <p className="text-yellow">Incomplete</p>}
          <h4 className="text-gray-500 truncate">{block.file?.name ?? 'Configure source'}</h4>
        </div>
      </div>
      <div className="flex flex-col items-start gap-2 ml-auto">
        {block?.sourceConditions?.length > 0 &&
          <div className="hover:shadow-inner text-blue text-sm font-semibold flex items-center justify-between relative w-24 rounded shadow bg-white border border-blue px-2 pt-1" onClick={(e) => { e.stopPropagation(); openModal(<WorkflowSourceConditionModal workflow={workflow} mutateWorkflow={mutateWorkflow} />) }}>
            Filtered
            <FilterIcon className="w-4 h-4 ml-1 mb-1" />
          </div>
        }
        {(block?.config?.dataGroupingField || block?.microsoftExcelConfig?.dataGroupingField) &&
          <div className="text-white text-sm font-semibold flex items-center justify-between relative w-24 rounded shadow bg-gray-300 px-2 pt-1">
            Grouped
            <CollectionIcon className="w-4 h-4 ml-1 mb-1" />
          </div>
        }
        {(block.sourceType === "GOOGLE_FORMS" && block?.file?.formId) &&
          <a className="hover:shadow-inner text-blue text-sm font-semibold flex items-center justify-between relative w-24 rounded shadow bg-white border border-blue px-2 pt-1" href={`https://docs.google.com/forms/d/${block!.file!.formId}/viewform`} target="_blank" rel="noreferrer" onClick={e => e.stopPropagation()}>
            Respond
            <ExternalLinkIcon className="w-4 h-4 ml-1 mb-1" />
          </a>
        }
      </div>
      {block.hubspotConfig?.hubspotPrimaryObjectType && (
        <div className="text-sm font-semibold flex items-center justify-center relative min:w-24 rounded shadow bg-gray-100 text-gray-500 px-2 pt-1">
          {block.hubspotConfig?.hubspotPrimaryObjectType}
        </div>
      )}
      {!block.hubspotConfig?.hubspotPrimaryObjectType && block.hubspotConfig?.hubspotPrimaryObject && (
        <div className="text-sm font-semibold flex items-center justify-center relative w-24 rounded shadow bg-gray-100 text-gray-500 px-2 pt-1">
          {capitalize(block.hubspotConfig?.hubspotPrimaryObject)}
        </div>
      )}
    </button>
  );
}

export default WorkflowOverviewBlockSource;

import DOMPurify from "dompurify";
import { ChangeEvent, useEffect, useMemo, useRef } from "react";
import { ALLOWED_TAGS } from "../utils";
import { useLocation } from "react-router-dom";
import PasteContentModal from "../components/PasteContentModal";
import { useModal } from "../contexts";

export type FormatType = 'bold' | 'italic' | 'underline' | 'align_left' | 'align_center' | 'align_right' | 'justifyLeft' | 'justifyCenter' | 'justifyRight' | 'insertUnorderedList' | 'insertOrderedList';

type Props = {
  editorRef: React.MutableRefObject<HTMLDivElement | null>;
  content: string;
  setContent: React.Dispatch<React.SetStateAction<string>> | ((val: string) => void);
  HTMLMode?: boolean;
  allowHTMLMode?: boolean;
  onFocus?: () => void;
  switchMode?: (value: boolean) => void;
}


export default function useRichTextfield(props: Props) {
  const { editorRef, content, setContent, HTMLMode = false, allowHTMLMode = false, onFocus, switchMode } = props;
  const lastSelectionRef = useRef<Range | null>(null);
  const htmlEditorRef = useRef<HTMLTextAreaElement | null>(null);
  const { openModal, closeModal } = useModal();
  const pathname = useLocation().pathname;

  const sanitizeHTML = (dangerousString: string) => {
    const safeString = DOMPurify.sanitize(dangerousString, { ALLOWED_TAGS });
    return safeString;
  }

  const handlePasteAttempt = () => {
    const pastePlainText = (clipText: string) => {
      document.execCommand('insertText', false, clipText);
      closeModal();
    }

    const switchToHTMLMode = () => {
      switchMode!(true);
      closeModal();
    }

    navigator.clipboard.readText().then(clipText => {
      const hasHTMLTags = /<[a-z][\s\S]*>/i.test(clipText);

      if (hasHTMLTags && allowHTMLMode) {
        openModal(<PasteContentModal switchToHTMLMode={switchToHTMLMode} pasteInPlainText={() => pastePlainText(clipText)} />)
      } else {
        document.execCommand('insertText', false, clipText);
      }
    });
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {

    if (e.key === 'v' && (e.ctrlKey || e.metaKey) && allowHTMLMode) {
      e.preventDefault();
      handlePasteAttempt();
    }

    if (e.key === 'Enter') {
      const selection = window.getSelection();
      if (selection && selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        const parentAnchor = range.startContainer.parentElement?.closest('a');

        if (parentAnchor) {
          e.preventDefault();
          const newLine = document.createElement('br');
          parentAnchor.insertAdjacentElement('afterend', newLine);
          const newRange = document.createRange();
          newRange.setStartAfter(newLine);
          newRange.collapse(true);
          selection.removeAllRanges();
          selection.addRange(newRange);
        }
      }
    }

    if (editorRef.current) {
      setContent(sanitizeHTML(editorRef.current.innerHTML));
      saveLastSelection();
    }
  };

  const handleHTMLChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setContent(e.target.value);
  }


  const saveLastSelection = () => {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      lastSelectionRef.current = selection.getRangeAt(0);
    }
  }

  const restoreLastSelection = () => {
    const selection = window.getSelection();
    const savedSelection = lastSelectionRef.current;
    if (!selection || !savedSelection) return;
    selection.removeAllRanges();
    selection.addRange(savedSelection);
  }
  const insertList = (format: FormatType) => {
    if (!editorRef.current) return;
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);

      // Get the parent block element containing the selection
      let block = range.startContainer;
      while (block && block.nodeType === Node.TEXT_NODE) {
        block = block.parentNode!;
      }

      // Create a new range that encompasses the entire line
      const lineRange = document.createRange();
      lineRange.selectNodeContents(block);

      const parentList = block.parentElement?.closest('ul, ol');
      if (parentList) {
        const newLine = document.createElement('li');
        newLine.appendChild(lineRange.extractContents());
        parentList.appendChild(newLine);
      } else {
        const newLine = document.createElement('li');
        newLine.appendChild(lineRange.extractContents());
        const list = document.createElement(format === 'insertOrderedList' ? 'ol' : 'ul');
        list.style.listStyle = format === "insertOrderedList" ? "auto" : "disc";
        list.style.paddingLeft = '40px';

        list.appendChild(newLine);
        lineRange.insertNode(list);
      }
      saveLastSelection();
      setContent(sanitizeHTML(editorRef.current.innerHTML));
    }
  }

  const applyFormat = (format: FormatType) => {
    if (!editorRef.current) return;
    if (['insertUnorderedList', 'insertOrderedList'].includes(format)) return insertList(format);

    document.execCommand(format, false, '');
    saveLastSelection();
    setContent(sanitizeHTML(editorRef.current.innerHTML));
  }

  const addLink = (text: string, url: string) => {
    if (!editorRef.current) return;

    const range = lastSelectionRef.current;

    if (range) {
      range.deleteContents();
      range.extractContents();

      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.target = '_blank';
      anchor.rel = 'noopener noreferrer';
      anchor.text = text;
      anchor.style.textDecoration = 'underline';
      anchor.style.color = 'blue';

      range.insertNode(anchor);
      saveLastSelection();
      setContent(sanitizeHTML(editorRef.current.innerHTML));
    }
  }

  const addImage = (imageUrl: string) => {
    if (!editorRef.current) return;

    const range = lastSelectionRef.current;
    const img = document.createElement('img');
    img.src = imageUrl;
    img.alt = 'User provided content';
    if (range) {
      img.appendChild(range.extractContents());
      range.insertNode(img);
      const newRange = document.createRange();
      newRange.setStartAfter(img);
      newRange.collapse(true);
    } else {
      editorRef.current.appendChild(img);
    }
    saveLastSelection();
    setContent(sanitizeHTML(editorRef.current.innerHTML));
  };

  const insertCustom = (newElement: HTMLElement) => {
    if (!editorRef.current || !(ALLOWED_TAGS.includes(newElement.tagName.toLowerCase()))) return;

    const range = lastSelectionRef.current;
    if (range) {
      newElement.appendChild(range.extractContents());
      range.insertNode(newElement);
      const newRange = document.createRange();
      newRange.setStartAfter(newElement);
      newRange.collapse(true);
    } else {
      editorRef.current.appendChild(newElement);
    }
    saveLastSelection();
    setContent(sanitizeHTML(editorRef.current.innerHTML));
  }

  const RichTextfield = useMemo(() => (
    <>
      {HTMLMode ? (
        <textarea
          ref={htmlEditorRef}
          onInputCapture={handleHTMLChange}
          className='h-full w-full min-h-[150px] outline-none border-none resize-none'
          onFocus={onFocus}
        />
      ) : (
        <div
          className="h-full min-h-[150px] outline-none border-none overflow-auto flex-grow"
          ref={editorRef}
          contentEditable
          onKeyDown={handleKeyDown}
          onKeyUp={saveLastSelection}
          onMouseUp={saveLastSelection}
          onMouseDown={saveLastSelection}
          dangerouslySetInnerHTML={{ __html: content }}
          suppressContentEditableWarning={true}
          onFocus={onFocus}
        />
      )}
    </>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [HTMLMode, editorRef, pathname]);

  useEffect(() => {
    if (!HTMLMode && editorRef.current?.innerHTML?.length === 0) {
      editorRef.current.innerHTML = content;
    }
    else if (HTMLMode && htmlEditorRef.current?.value?.length === 0) {
      htmlEditorRef.current.value = content;
    }
  }, [HTMLMode, content, editorRef]);

  return {
    RichTextfield,
    applyFormat,
    addLink,
    addImage,
    restoreLastSelection,
    insertCustom
  }
}

